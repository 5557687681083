<template>
    <div class="body">
        <hr />
        <input ref="input"
               type="file"
               name="image"
               accept="image/*"
               @change="setImage" />
        <div class="content">
            <section class="cropper-area">
                <div class="img-cropper">
                    <vue-cropper ref="cropper"
                                 :aspect-ratio="3 / 2"
                                 :src="imgSrc"
                                 preview=".preview" />
                </div>
                <div class="actions">
                    <a ref="flipX" href="#" role="button" @click.prevent="flipX">左右翻转</a>
                    <a ref="flipY" href="#" role="button" @click.prevent="flipY">上下翻转</a>
                    <a href="#" role="button" @click.prevent="showFileChooser">上传图片</a>
                    <a href="#" role="button" @click.prevent="cropImage">剪切</a>
                    <a href="#" role="button" @click.prevent="reset">重置</a>
                    <a href="#" role="button" @click.prevent="onPostFile">数据上传</a>
                    <!--<a href="#" role="button" @click.prevent="setData">设置长宽600px*400px</a>-->
                </div>

            </section>
            <section class="preview-area">
                <p>预览</p>
                <div class="preview" />
                <p>裁剪图像</p>
                <div class="cropped-image">
                    <img v-if="cropImg"
                         :src="cropImg"
                         alt="Cropped Image" />
                    <div v-else class="crop-placeholder" />
                </div>
            </section>
        </div>
    </div>
</template>

<script>
  // Local
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import ajax from './ajax';
    import Images from '@/assets/images/berserk.jpg'

  export default {
    components: {
      VueCropper,
    },
    props: {
      action: String,
    },
    data() {
      return {
          imgSrc: Images,
        cropImg: '',
          data: '{"width":600,"height":400,"rotate":0,"scaleX":1,"scaleY":1}',

        reqs: {},
        uploadedImageType : 'image/jpeg'
      };
    },
    methods: {
      onPostFile() {
        const options = {
            file: this.convertBase64UrlToBlob(this.cropImg, 'currle.jpg'),//rawFile,
          filename: "file",//this.name,
            action: this.action,//"https:/hr.gradgroup.cn/uc/api/Attachment/Upload",
            headers: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
          onProgress: e => {
            console.log("onProgress",e);
          },
          onSuccess: res => {
              console.log("onSuccess", res);
              this.$emit("callback", { newdata: res }); //将值传给父组件
          },
          onError: err => {
            console.log("onError", err);
          }
        };
        const req = ajax(options);
        console.log("ajax(options)", req);
        if (req && req.then) {
          req.then(options.onSuccess, options.onError);
        }
      },
      convertBase64UrlToBlob(dataurl, filename){
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      } ,

      cropImage() {
        // get image data for post processing, e.g. upload or setting image src
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(this.uploadedImageType);
      },
      flipX() {
        const dom = this.$refs.flipX;
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleX(scale);
        dom.setAttribute('data-scale', scale);
      },
      flipY() {
        const dom = this.$refs.flipY;
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleY(scale);
        dom.setAttribute('data-scale', scale);
      },
      getCropBoxData() {
        this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
      },
      getData() {
        this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
      },
      move(offsetX, offsetY) {
        this.$refs.cropper.move(offsetX, offsetY);
      },
      reset() {
        this.$refs.cropper.reset();
      },
      rotate(deg) {
        this.$refs.cropper.rotate(deg);
      },
      setCropBoxData() {
        if (!this.data) return;

        this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
      },
      setData() {
        if (!this.data) return;
        this.$refs.cropper.setData(JSON.parse(this.data));
      },
      setImage(e) {
        const file = e.target.files[0];

        if (file.type.indexOf('image/') === -1) {
          alert('请选择一个图片文件');
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      showFileChooser() {
        this.$refs.input.click();
        //alert(this.imgSrc)
      },
      zoom(percent) {
        this.$refs.cropper.relativeZoom(percent);
      },
    },
  };

</script>

<style scoped>
  .body {
    font-family: Arial, Helvetica, sans-serif;
    width: 955px;
    margin: 0 auto;
  }

  input[type="file"] {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 5px 0;
  }

    .header h2 {
      margin: 0;
    }

    .header a {
      text-decoration: none;
      color: black;
    }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .cropper-area {
    width: 614px;
  }

  .actions {
    margin-top: 1rem;
  }

    .actions a {
      display: inline-block;
      padding: 5px 15px;
      background: #0062CC;
      color: white;
      text-decoration: none;
      border-radius: 3px;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

  textarea {
    width: 100%;
    height: 100px;
  }

  .preview-area {
    width: 307px;
  }

    .preview-area p {
      font-size: 1.25rem;
      margin: 0;
      margin-bottom: 1rem;
    }

      .preview-area p:last-of-type {
        margin-top: 1rem;
      }

  .preview {
    width: 100%;
    height: calc(372px * (9 / 16));
    overflow: hidden;
  }

  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }

  .cropped-image img {
    max-width: 100%;
  }
</style>
